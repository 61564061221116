import template from './cookie-button.template';
/**
 * define "<cookie-button>"
 * element to toggle the "cookie dialog"
 */
class CookieButton extends HTMLElement {
  static htmlTemplate = template;

  constructor() {
    super();

    // Attach Shadow DOM
    const shadow = this.attachShadow({ mode: 'open' });

    // Clone the template content into the Shadow DOM
    const cookieButtonTemplate = document.createElement('template');
    cookieButtonTemplate.innerHTML = CookieButton.htmlTemplate;
    shadow.append(cookieButtonTemplate.content.cloneNode(true));
    this.handleOpen = this.handleOpen.bind(this);
    const openCookieConsentButton = this.shadowRoot.querySelector('#cookieButton');
    openCookieConsentButton.addEventListener('click', this.handleOpen);
  }

  handleOpen() {
    const CookieConsentEvent = new CustomEvent('cookiedialogopen', {
      detail: { buttonevent: 'cookie open' },
      bubbles: true,
      composed: true, // break out of shadowroot
    });
    this.shadowRoot.dispatchEvent(CookieConsentEvent);
  }
}

export default CookieButton;
