import localization from './cookie-setting.localization';
import template from './cookie-setting.template';
/**
 * define "<cookie-setting>"
 * the tag can have the "lang" attribute to define the languae that should be used
 */
class CookieSetting extends HTMLElement {
  static localizationData = localization;

  static htmlTemplate = template;

  trackingCheckbox;

  currentInfo;

  statLabel;

  funcLabel;

  constructor() {
    super();
    const cookieSettingsTemplate = document.createElement('template');
    cookieSettingsTemplate.innerHTML = CookieSetting.htmlTemplate;
    this.append(cookieSettingsTemplate.content.cloneNode(true));

    // Select elements
    this.legend = this.querySelector('legend');
    this.currentInfo = this.querySelector('#currentInfo');
    this.statLabel = this.querySelector('#statLabel');
    this.funcLabel = this.querySelector('#funcLabel');
    this.trackingCheckbox = this.querySelector('#trackingCheckbox');

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.displayChoosenValue = this.displayChoosenValue.bind(this);
    this.localize = this.localize.bind(this);

    this.trackingCheckbox.addEventListener('change', this.handleCheckboxChange);
    document.addEventListener('cookiedialogconsentevent', () => this.displayChoosenValue());

    // Set localized content
    this.localize();

    this.displayChoosenValue();
  }

  localize() {
    // read the language attribute if not present use the browser language
    const language = (this.lang ? this.lang : navigator.language).slice(0, 2).toLowerCase();
    // Set localized content
    if (Object.keys(CookieSetting.localizationData).includes(language)) {
      const localizedData = CookieSetting.localizationData[language];
      this.legend.textContent = localizedData.CookieLegend;
      this.currentInfo.textContent = localizedData.CookieSettingText;
      this.statLabel.textContent = localizedData.CookieTracking;
      this.funcLabel.textContent = localizedData.CookieFunctional;
      this.lang = language;
    } else {
      this.removeAttribute('lang');
    }
  }

  displayChoosenValue() {
    this.customerDecision = localStorage.getItem('cookieTrackingConsent');
    this.trackingCheckbox.checked = this.customerDecision === 'accepted';
  }

  handleCheckboxChange(event) {
    let CookieConsentEvent;
    if (event.target.checked) {
      localStorage.setItem('cookieTrackingConsent', 'accepted');
      CookieConsentEvent = new CustomEvent('cookiedialogconsentevent', {
        detail: { cookieTracking: 'accepted' },
        bubbles: true,
      });
    } else {
      localStorage.setItem('cookieTrackingConsent', 'rejected');
      CookieConsentEvent = new CustomEvent('cookiedialogconsentevent', {
        detail: { cookieTracking: 'rejected' },
        bubbles: true,
      });
    }
    this.dispatchEvent(CookieConsentEvent);
  }
}

export default CookieSetting;
