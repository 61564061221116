import template from './cookie-dialog.template';
import localization from './cookie-dialog.localization';
/**
 * define "<cookie-dialog>"
 * the tag can have the "lang" attribute to define the language that should be used
 * the tag has the data-more-link data-attribute to inject the link to use on the "more info Link"
 */
class CookieDialog extends HTMLElement {
  static localizationData = localization;

  static htmlTemplate = template;

  infoLink;

  closeCookieConsentButton;

  handleTrackingAcceptButton;

  handleTrackingRejectButton;

  cookieConsentDialog;

  acceptedText;

  rejectedText;

  consentIsSet;

  /**
   *
   */
  constructor() {
    super();

    // Attach Shadow DOM
    const shadow = this.attachShadow({ mode: 'open' });

    // Clone the template content into the Shadow DOM
    const cookieConsentTemplate = document.createElement('template');
    cookieConsentTemplate.innerHTML = CookieDialog.htmlTemplate;
    shadow.appendChild(cookieConsentTemplate.content.cloneNode(true));

    // methods
    this.handleToggleButtonClick = this.handleToggleButtonClick.bind(this);
    this.handleCloseDialogClick = this.handleCloseDialogClick.bind(this);
    this.handleTrackingAccept = this.handleTrackingAccept.bind(this);
    this.handleTrackingReject = this.handleTrackingReject.bind(this);
    this.localize = this.localize.bind(this);
    this.setText = this.setText.bind(this);
    this.setStyles = this.setStyles.bind(this);
    this.setState = this.setState.bind(this);
    this.openModal = this.openModal.bind(this);

    // Select elements within the Shadow DOM
    this.acceptedText = this.shadowRoot.querySelector('#acceptedText');
    this.rejectedText = this.shadowRoot.querySelector('#rejectedText');
    this.closeCookieConsentButton = this.shadowRoot.querySelector('#closeCookieConsent');
    this.cookieConsentDialog = this.shadowRoot.querySelector('#cookieConsentDialog');
    this.handleTrackingRejectButton = this.shadowRoot.querySelector('#rejectButton');
    this.handleTrackingAcceptButton = this.shadowRoot.querySelector('#acceptButton');
    this.infoLink = this.shadowRoot.querySelector('#infoLink');

    // add "more infos link"
    this.infoLink.href = this.dataset.moreLink;

    if(this.dataset.linkColor) {
      this.shadowRoot.querySelectorAll('a').forEach((a) => {
        a.style.color = this.dataset.linkColor;
      })
    }

    this.localize();

    // EventListeners
    /* openCookieConsentButton.addEventListener('click', this.handleToggleButtonClick) */
    this.closeCookieConsentButton.addEventListener('click', this.handleCloseDialogClick);
    document.addEventListener('cookiedialogopen', this.handleToggleButtonClick);
    document.addEventListener('cookiedialogconsentevent', () => this.setText());
    this.handleTrackingAcceptButton.addEventListener('click', this.handleTrackingAccept);
    this.handleTrackingRejectButton.addEventListener('click', this.handleTrackingReject);
    // Prevent the dialog from closing when the Escape key is pressed
    this.cookieConsentDialog.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    });

    // Get the initial state of dialog
    this.setText();
    // set the initial styles
    this.setStyles();

    this.setState();
  }

  /**
   *
   */
  setStyles() {
    if (this.dataset.dialogMode === 'footer') {
      this.cookieConsentDialog.style.position = 'fixed';
      this.cookieConsentDialog.style.zIndex = '9999';
      this.cookieConsentDialog.style.boxSizing = 'border-box';
      this.cookieConsentDialog.style.bottom = '0';
      this.cookieConsentDialog.style.maxWidth = '100%';
      this.cookieConsentDialog.style.width = '100%';
    }
  }

  /**
   *
   */
  setText() {
    this.consentIsSet = localStorage.getItem('cookieTrackingConsent');
    if (this.consentIsSet) {
      this.cookieConsentDialog.close();
      this.closeCookieConsentButton.style.display = 'block';
      if (this.consentIsSet === 'accepted') {
        this.acceptedText.style.display = 'block';
        this.rejectedText.style.display = 'none';
      } else if (this.consentIsSet === 'rejected') {
        this.acceptedText.style.display = 'none';
        this.rejectedText.style.display = 'block';
      }
    } else {
      this.closeCookieConsentButton.style.display = 'none';
    }
  }

  /**
   *
   */
  setState() {
    if (!this.consentIsSet) {
      this.openModal();
    }
  }

  /**
   *
   */
  openModal() {
    if (this.dataset.dialogMode === 'footer') {
      this.cookieConsentDialog.show();
    } else {
      this.cookieConsentDialog.showModal();
    }
  }

  /**
   *
   */
  localize() {
    // read the language attribute if not present use the browser language
    const language = (this.lang ? this.lang : navigator.language).slice(0, 2).toLowerCase();
    // Set localized content
    if (Object.keys(CookieDialog.localizationData).includes(language)) {
      const localizedData = CookieDialog.localizationData[language];
      this.shadowRoot.querySelector('#cookieTitle').textContent = localizedData.CookieTitle;
      this.shadowRoot.querySelector('#cookieText').textContent = localizedData.CookieText;
      this.acceptedText.textContent = localizedData.CookieYourChoiceAccepted;
      this.rejectedText.textContent = localizedData.CookieYourChoiceRefused;
      this.cookieConsentDialog.ariaLabel = localizedData.closeDialog;
      this.handleTrackingRejectButton.textContent = localizedData.RejectStatisticCookies;
      this.handleTrackingAcceptButton.textContent = localizedData.AcceptStatisticCookies;
      this.infoLink.textContent = localizedData.moreInfos;
      this.lang = language;
    } else {
      this.removeAttribute('lang');
    }
  }

  /**
   *
   */
  handleToggleButtonClick() {
    this.consentIsSet = localStorage.getItem('cookieTrackingConsent');
    if (this.consentIsSet) {
      this.cookieConsentDialog.close();
      this.shadowRoot.querySelector('#closeCookieConsent').style.display = 'block';
    } else {
      this.shadowRoot.querySelector('#closeCookieConsent').style.display = 'none';
    }
    this.openModal();
  }

  /**
   *
   */
  handleCloseDialogClick() {
    if (this.cookieConsentDialog) {
      this.cookieConsentDialog.close();
    }
  }

  /**
   *
   */
  handleTrackingAccept() {
    const CookieConsentEvent = new CustomEvent('cookiedialogconsentevent', {
      detail: { cookieTracking: 'accepted' },
      bubbles: true,
      composed: true, // to break out of shadowRoot
    });
    localStorage.setItem('cookieTrackingConsent', 'accepted');
    localStorage.setItem('cookieFunctionalConsent', 'ok');
    this.shadowRoot.dispatchEvent(CookieConsentEvent);
    this.shadowRoot.querySelector('#cookieConsentDialog').close();
    this.shadowRoot.querySelector('#acceptedText').style.display = 'block';
    this.shadowRoot.querySelector('#rejectedText').style.display = 'none';
  }

  /**
   *
   */
  handleTrackingReject() {
    const CookieConsentEvent = new CustomEvent('cookiedialogconsentevent', {
      detail: { cookieTracking: 'rejected' },
      bubbles: true,
      composed: true, // to break out of shadowRoot
    });
    localStorage.setItem('cookieTrackingConsent', 'rejected');
    localStorage.setItem('cookieFunctionalConsent', 'ok');
    this.shadowRoot.dispatchEvent(CookieConsentEvent);
    this.shadowRoot.querySelector('#cookieConsentDialog').close();
    this.shadowRoot.querySelector('#acceptedText').style.display = 'none';
    this.shadowRoot.querySelector('#rejectedText').style.display = 'block';
  }
}

export default CookieDialog;
