const localization = {
  en: {
    CookieLegend: 'Your cookies settings',
    CookieReloadText: "Dear visitor, if you change your cookie settings, we need to reload the page for the changes to take effect. Don't worry, this will only take a moment.",
    CookieSettingText: 'You are currently using the following cookie settings:',
    CookieTracking: 'Tracking cookies',
    CookieFunctional: 'Functional cookies',
  },
  fr: {
    CookieLegend: 'Vos paramètres de cookies',
    CookieReloadText: "Cher visiteur, si vous modifiez vos paramètres de cookies, nous devons recharger la page pour que les modifications prennent effet. Ne vous inquiétez pas, cela ne prendra qu'un instant.",
    CookieSettingText: "Pour l'instant vous utilisez le paramétrage suivant pour les cookies : ",
    CookieTracking: 'Cookies statistiques',
    CookieFunctional: 'Cookies fonctionels',
  },
  de: {
    CookieLegend: 'Ihre Cookie-Einstellungen',
    CookieReloadText: 'Liebe Besucherin, lieber Besucher, wenn Sie Ihre Cookie-Einstellungen ändern, müssen wir die Seite neu laden, damit die Änderungen wirksam werden. Keine Sorge, das dauert nur einen Augenblick.',
    CookieSettingText: 'Derzeit verwenden Sie die folgenden Einstellungen für Cookies:',
    CookieTracking: 'Statistik Cookies',
    CookieFunctional: 'Funktionale Cookies',
  },
};

export default localization;
