const template = `
  <form>
    <fieldset>  
      <legend>Votre choix pour les cookies</legend>
      <p id="currentInfo">Pour l'instant vous utilisez le paramétrage suivant pour les cookies :&nbsp;</p>      
      <div class="form-check">
       
        <label class="form-check-label" for="trackingCheckbox">
           <input class="form-check-input" id="trackingCheckbox" type="checkbox">
          <span id="statLabel">Cookies statistiques</span>
        </label>
      </div>
      <div class="form-check">
        <label class="form-check-label" for="functionalCheckbox">
          <input checked class="form-check-input" disabled id="functionalCheckbox" type="checkbox">
          <span id="funcLabel">Cookies fonctionels</span>
        </label>
      </div>
    </fieldset>
  </form>`;

export default template;
