const localization = {
  en: {
    CookieTitle: 'Cookies',
    CookieText: 'This website uses cookies to guarantee you an optimal experience.',
    CookieYourChoiceAccepted: 'You have accepted the tracking cookies.',
    CookieYourChoiceRefused: 'You did not accept the tracking cookies',
    RejectStatisticCookies: 'Use necessary cookies only',
    AcceptStatisticCookies: 'Accept all cookies',
    moreInfos: 'More infos',
    closeDialog: 'Close dialog',
  },
  fr: {
    CookieTitle: 'Cookies',
    CookieText: 'Ce site web utilise des cookies pour vous garantir une expérience optimale.',
    CookieYourChoiceAccepted: 'Vous avez accepté les cookies statistiques.',
    CookieYourChoiceRefused: 'Vous avez refusé les cookies statistiques',
    RejectStatisticCookies: 'Accepter uniquement les cookies nécessaires',
    AcceptStatisticCookies: 'Accepter tous les cookies',
    moreInfos: "Plus d'informations",
    closeDialog: 'fermer cette message',
  },
  de: {
    CookieTitle: 'Cookies',
    CookieText: 'Diese Website verwendet Cookies, um Ihnen die bestmögliche Nutzung unserer Website zu ermöglichen.',
    CookieYourChoiceAccepted: 'Sie haben die Statistikcookies akzeptiert.',
    CookieYourChoiceRefused: 'Sie haben die Statistikcookies abgelehnt',
    RejectStatisticCookies: 'Nur benötigte Cookies akzeptieren',
    AcceptStatisticCookies: 'Alle Cookies akzeptieren',
    moreInfos: 'Mehr Informationen',
    closeDialog: 'Dialog schliessen',
  },
};

export default localization;
