const template = `<style>
    
    * {
        font-family: sans-serif;
    }
    
    a {
        text-decoration: none;
    }
    
    a:hover {
        filter: brightness(50%);
    }
    
    p {
        line-height: 1.5;
        margin-block-start: 0;
        margin-block-end: 0.5em;
    }
    
    dialog {
      line-height: 0.5;
      border: none !important;
      border-radius: 0.25rem;
      box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
      padding: 1.6rem;
      max-width: 400px;
    }
    
    ::backdrop {
        /*background-color: aqua;*/
    }
    
    button {
        font-size: 1rem;
        background-color: #e1e1e1; /* Green */
        border: none;
        /*border-radius: .25rem;*/
        color: black;
        padding: .5rem 1rem;
        text-align: center;
        text-decoration: none;
        display: inline-block;
      }
      button:focus, button:hover, button:active {
          outline-color: inherit;
          outline-style:solid;
          box-shadow: 0 0 10px 2px rgba(0,0,0,0.5);
      }
      
     #closeCookieConsent {
        position: absolute;
        top: .5rem;
        right: .5rem;
        padding: .25rem .5rem;
     }
     #acceptButton, #rejectButton {
     display: block;
     flex: 1 0;
     width: 100%;
     margin: 0 auto;
     }
     #acceptButton {
        color: white;
        background-color: #007502;
     }
     .limit-container {
     margin-right: auto;
     margin-left: auto;
     max-width: 800px;
     }
     h1 {
     font-size: 1.4rem;
     }
     #buttonContainer {
        display: flex;
        gap: 0.5em;
        flex-flow: row wrap;
        margin-block-end: 1em;
     }
     
    </style>  
    <!--<button id="openCookieConsent">Cookies</button>-->
    <dialog id="cookieConsentDialog">
        <div class="limit-container">
            <h1 id="cookieTitle">Cookies</h1>
            <p id="acceptedText" style="display: none; font-weight: bold">You have accepted the tracking cookies.</p>
            <p id="rejectedText" style="display: none; font-weight: bold">You did not accept the tracking cookies'</p>
            <p id="cookieText">This website uses cookies to guarantee you an optimal experience.</p>
            <div id="buttonContainer">
                <button id="acceptButton" class="btn btn-primary mr-2">Accept all cookies</button>
                <button id="rejectButton" class="btn btn-secondary mr-2">Use necessary cookies only</button>
            </div>
            <a id="infoLink" href="#">More infos</a>
        </div>
        <button id="closeCookieConsent" aria-label="Close dialog">&#10006;</button> <!-- &#10006; is the HTML entity for the "×" character -->
    </dialog>
    `;

export default template;
